// Text wrapping utility classes
.wrap {
  white-space: pre-wrap;
}

.wrap-line {
  white-space: pre-line;
}

.word-wrap {
  word-break: normal;
  white-space: normal;
}
