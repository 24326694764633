@charset "UTF-8";
html,
body {
  height: 100%;
}

router-outlet {
  display: none;
}

:is(button, [role=button]):not([disabled]) {
  cursor: pointer;
}

.wrap {
  white-space: pre-wrap;
}

.wrap-line {
  white-space: pre-line;
}

.word-wrap {
  word-break: normal;
  white-space: normal;
}

.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
}

.fi:before {
  content: " ";
}

.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url(../flags/4x3/xx.svg);
}

.fi-xx.fis {
  background-image: url(../flags/1x1/xx.svg);
}

.fi-ad {
  background-image: url(../flags/4x3/ad.svg);
}

.fi-ad.fis {
  background-image: url(../flags/1x1/ad.svg);
}

.fi-ae {
  background-image: url(../flags/4x3/ae.svg);
}

.fi-ae.fis {
  background-image: url(../flags/1x1/ae.svg);
}

.fi-af {
  background-image: url(../flags/4x3/af.svg);
}

.fi-af.fis {
  background-image: url(../flags/1x1/af.svg);
}

.fi-ag {
  background-image: url(../flags/4x3/ag.svg);
}

.fi-ag.fis {
  background-image: url(../flags/1x1/ag.svg);
}

.fi-ai {
  background-image: url(../flags/4x3/ai.svg);
}

.fi-ai.fis {
  background-image: url(../flags/1x1/ai.svg);
}

.fi-al {
  background-image: url(../flags/4x3/al.svg);
}

.fi-al.fis {
  background-image: url(../flags/1x1/al.svg);
}

.fi-am {
  background-image: url(../flags/4x3/am.svg);
}

.fi-am.fis {
  background-image: url(../flags/1x1/am.svg);
}

.fi-ao {
  background-image: url(../flags/4x3/ao.svg);
}

.fi-ao.fis {
  background-image: url(../flags/1x1/ao.svg);
}

.fi-aq {
  background-image: url(../flags/4x3/aq.svg);
}

.fi-aq.fis {
  background-image: url(../flags/1x1/aq.svg);
}

.fi-ar {
  background-image: url(../flags/4x3/ar.svg);
}

.fi-ar.fis {
  background-image: url(../flags/1x1/ar.svg);
}

.fi-as {
  background-image: url(../flags/4x3/as.svg);
}

.fi-as.fis {
  background-image: url(../flags/1x1/as.svg);
}

.fi-at {
  background-image: url(../flags/4x3/at.svg);
}

.fi-at.fis {
  background-image: url(../flags/1x1/at.svg);
}

.fi-au {
  background-image: url(../flags/4x3/au.svg);
}

.fi-au.fis {
  background-image: url(../flags/1x1/au.svg);
}

.fi-aw {
  background-image: url(../flags/4x3/aw.svg);
}

.fi-aw.fis {
  background-image: url(../flags/1x1/aw.svg);
}

.fi-ax {
  background-image: url(../flags/4x3/ax.svg);
}

.fi-ax.fis {
  background-image: url(../flags/1x1/ax.svg);
}

.fi-az {
  background-image: url(../flags/4x3/az.svg);
}

.fi-az.fis {
  background-image: url(../flags/1x1/az.svg);
}

.fi-ba {
  background-image: url(../flags/4x3/ba.svg);
}

.fi-ba.fis {
  background-image: url(../flags/1x1/ba.svg);
}

.fi-bb {
  background-image: url(../flags/4x3/bb.svg);
}

.fi-bb.fis {
  background-image: url(../flags/1x1/bb.svg);
}

.fi-bd {
  background-image: url(../flags/4x3/bd.svg);
}

.fi-bd.fis {
  background-image: url(../flags/1x1/bd.svg);
}

.fi-be {
  background-image: url(../flags/4x3/be.svg);
}

.fi-be.fis {
  background-image: url(../flags/1x1/be.svg);
}

.fi-bf {
  background-image: url(../flags/4x3/bf.svg);
}

.fi-bf.fis {
  background-image: url(../flags/1x1/bf.svg);
}

.fi-bg {
  background-image: url(../flags/4x3/bg.svg);
}

.fi-bg.fis {
  background-image: url(../flags/1x1/bg.svg);
}

.fi-bh {
  background-image: url(../flags/4x3/bh.svg);
}

.fi-bh.fis {
  background-image: url(../flags/1x1/bh.svg);
}

.fi-bi {
  background-image: url(../flags/4x3/bi.svg);
}

.fi-bi.fis {
  background-image: url(../flags/1x1/bi.svg);
}

.fi-bj {
  background-image: url(../flags/4x3/bj.svg);
}

.fi-bj.fis {
  background-image: url(../flags/1x1/bj.svg);
}

.fi-bl {
  background-image: url(../flags/4x3/bl.svg);
}

.fi-bl.fis {
  background-image: url(../flags/1x1/bl.svg);
}

.fi-bm {
  background-image: url(../flags/4x3/bm.svg);
}

.fi-bm.fis {
  background-image: url(../flags/1x1/bm.svg);
}

.fi-bn {
  background-image: url(../flags/4x3/bn.svg);
}

.fi-bn.fis {
  background-image: url(../flags/1x1/bn.svg);
}

.fi-bo {
  background-image: url(../flags/4x3/bo.svg);
}

.fi-bo.fis {
  background-image: url(../flags/1x1/bo.svg);
}

.fi-bq {
  background-image: url(../flags/4x3/bq.svg);
}

.fi-bq.fis {
  background-image: url(../flags/1x1/bq.svg);
}

.fi-br {
  background-image: url(../flags/4x3/br.svg);
}

.fi-br.fis {
  background-image: url(../flags/1x1/br.svg);
}

.fi-bs {
  background-image: url(../flags/4x3/bs.svg);
}

.fi-bs.fis {
  background-image: url(../flags/1x1/bs.svg);
}

.fi-bt {
  background-image: url(../flags/4x3/bt.svg);
}

.fi-bt.fis {
  background-image: url(../flags/1x1/bt.svg);
}

.fi-bv {
  background-image: url(../flags/4x3/bv.svg);
}

.fi-bv.fis {
  background-image: url(../flags/1x1/bv.svg);
}

.fi-bw {
  background-image: url(../flags/4x3/bw.svg);
}

.fi-bw.fis {
  background-image: url(../flags/1x1/bw.svg);
}

.fi-by {
  background-image: url(../flags/4x3/by.svg);
}

.fi-by.fis {
  background-image: url(../flags/1x1/by.svg);
}

.fi-bz {
  background-image: url(../flags/4x3/bz.svg);
}

.fi-bz.fis {
  background-image: url(../flags/1x1/bz.svg);
}

.fi-ca {
  background-image: url(../flags/4x3/ca.svg);
}

.fi-ca.fis {
  background-image: url(../flags/1x1/ca.svg);
}

.fi-cc {
  background-image: url(../flags/4x3/cc.svg);
}

.fi-cc.fis {
  background-image: url(../flags/1x1/cc.svg);
}

.fi-cd {
  background-image: url(../flags/4x3/cd.svg);
}

.fi-cd.fis {
  background-image: url(../flags/1x1/cd.svg);
}

.fi-cf {
  background-image: url(../flags/4x3/cf.svg);
}

.fi-cf.fis {
  background-image: url(../flags/1x1/cf.svg);
}

.fi-cg {
  background-image: url(../flags/4x3/cg.svg);
}

.fi-cg.fis {
  background-image: url(../flags/1x1/cg.svg);
}

.fi-ch {
  background-image: url(../flags/4x3/ch.svg);
}

.fi-ch.fis {
  background-image: url(../flags/1x1/ch.svg);
}

.fi-ci {
  background-image: url(../flags/4x3/ci.svg);
}

.fi-ci.fis {
  background-image: url(../flags/1x1/ci.svg);
}

.fi-ck {
  background-image: url(../flags/4x3/ck.svg);
}

.fi-ck.fis {
  background-image: url(../flags/1x1/ck.svg);
}

.fi-cl {
  background-image: url(../flags/4x3/cl.svg);
}

.fi-cl.fis {
  background-image: url(../flags/1x1/cl.svg);
}

.fi-cm {
  background-image: url(../flags/4x3/cm.svg);
}

.fi-cm.fis {
  background-image: url(../flags/1x1/cm.svg);
}

.fi-cn {
  background-image: url(../flags/4x3/cn.svg);
}

.fi-cn.fis {
  background-image: url(../flags/1x1/cn.svg);
}

.fi-co {
  background-image: url(../flags/4x3/co.svg);
}

.fi-co.fis {
  background-image: url(../flags/1x1/co.svg);
}

.fi-cr {
  background-image: url(../flags/4x3/cr.svg);
}

.fi-cr.fis {
  background-image: url(../flags/1x1/cr.svg);
}

.fi-cu {
  background-image: url(../flags/4x3/cu.svg);
}

.fi-cu.fis {
  background-image: url(../flags/1x1/cu.svg);
}

.fi-cv {
  background-image: url(../flags/4x3/cv.svg);
}

.fi-cv.fis {
  background-image: url(../flags/1x1/cv.svg);
}

.fi-cw {
  background-image: url(../flags/4x3/cw.svg);
}

.fi-cw.fis {
  background-image: url(../flags/1x1/cw.svg);
}

.fi-cx {
  background-image: url(../flags/4x3/cx.svg);
}

.fi-cx.fis {
  background-image: url(../flags/1x1/cx.svg);
}

.fi-cy {
  background-image: url(../flags/4x3/cy.svg);
}

.fi-cy.fis {
  background-image: url(../flags/1x1/cy.svg);
}

.fi-cz {
  background-image: url(../flags/4x3/cz.svg);
}

.fi-cz.fis {
  background-image: url(../flags/1x1/cz.svg);
}

.fi-de {
  background-image: url(../flags/4x3/de.svg);
}

.fi-de.fis {
  background-image: url(../flags/1x1/de.svg);
}

.fi-dj {
  background-image: url(../flags/4x3/dj.svg);
}

.fi-dj.fis {
  background-image: url(../flags/1x1/dj.svg);
}

.fi-dk {
  background-image: url(../flags/4x3/dk.svg);
}

.fi-dk.fis {
  background-image: url(../flags/1x1/dk.svg);
}

.fi-dm {
  background-image: url(../flags/4x3/dm.svg);
}

.fi-dm.fis {
  background-image: url(../flags/1x1/dm.svg);
}

.fi-do {
  background-image: url(../flags/4x3/do.svg);
}

.fi-do.fis {
  background-image: url(../flags/1x1/do.svg);
}

.fi-dz {
  background-image: url(../flags/4x3/dz.svg);
}

.fi-dz.fis {
  background-image: url(../flags/1x1/dz.svg);
}

.fi-ec {
  background-image: url(../flags/4x3/ec.svg);
}

.fi-ec.fis {
  background-image: url(../flags/1x1/ec.svg);
}

.fi-ee {
  background-image: url(../flags/4x3/ee.svg);
}

.fi-ee.fis {
  background-image: url(../flags/1x1/ee.svg);
}

.fi-eg {
  background-image: url(../flags/4x3/eg.svg);
}

.fi-eg.fis {
  background-image: url(../flags/1x1/eg.svg);
}

.fi-eh {
  background-image: url(../flags/4x3/eh.svg);
}

.fi-eh.fis {
  background-image: url(../flags/1x1/eh.svg);
}

.fi-er {
  background-image: url(../flags/4x3/er.svg);
}

.fi-er.fis {
  background-image: url(../flags/1x1/er.svg);
}

.fi-es {
  background-image: url(../flags/4x3/es.svg);
}

.fi-es.fis {
  background-image: url(../flags/1x1/es.svg);
}

.fi-et {
  background-image: url(../flags/4x3/et.svg);
}

.fi-et.fis {
  background-image: url(../flags/1x1/et.svg);
}

.fi-fi {
  background-image: url(../flags/4x3/fi.svg);
}

.fi-fi.fis {
  background-image: url(../flags/1x1/fi.svg);
}

.fi-fj {
  background-image: url(../flags/4x3/fj.svg);
}

.fi-fj.fis {
  background-image: url(../flags/1x1/fj.svg);
}

.fi-fk {
  background-image: url(../flags/4x3/fk.svg);
}

.fi-fk.fis {
  background-image: url(../flags/1x1/fk.svg);
}

.fi-fm {
  background-image: url(../flags/4x3/fm.svg);
}

.fi-fm.fis {
  background-image: url(../flags/1x1/fm.svg);
}

.fi-fo {
  background-image: url(../flags/4x3/fo.svg);
}

.fi-fo.fis {
  background-image: url(../flags/1x1/fo.svg);
}

.fi-fr {
  background-image: url(../flags/4x3/fr.svg);
}

.fi-fr.fis {
  background-image: url(../flags/1x1/fr.svg);
}

.fi-ga {
  background-image: url(../flags/4x3/ga.svg);
}

.fi-ga.fis {
  background-image: url(../flags/1x1/ga.svg);
}

.fi-gb {
  background-image: url(../flags/4x3/gb.svg);
}

.fi-gb.fis {
  background-image: url(../flags/1x1/gb.svg);
}

.fi-gd {
  background-image: url(../flags/4x3/gd.svg);
}

.fi-gd.fis {
  background-image: url(../flags/1x1/gd.svg);
}

.fi-ge {
  background-image: url(../flags/4x3/ge.svg);
}

.fi-ge.fis {
  background-image: url(../flags/1x1/ge.svg);
}

.fi-gf {
  background-image: url(../flags/4x3/gf.svg);
}

.fi-gf.fis {
  background-image: url(../flags/1x1/gf.svg);
}

.fi-gg {
  background-image: url(../flags/4x3/gg.svg);
}

.fi-gg.fis {
  background-image: url(../flags/1x1/gg.svg);
}

.fi-gh {
  background-image: url(../flags/4x3/gh.svg);
}

.fi-gh.fis {
  background-image: url(../flags/1x1/gh.svg);
}

.fi-gi {
  background-image: url(../flags/4x3/gi.svg);
}

.fi-gi.fis {
  background-image: url(../flags/1x1/gi.svg);
}

.fi-gl {
  background-image: url(../flags/4x3/gl.svg);
}

.fi-gl.fis {
  background-image: url(../flags/1x1/gl.svg);
}

.fi-gm {
  background-image: url(../flags/4x3/gm.svg);
}

.fi-gm.fis {
  background-image: url(../flags/1x1/gm.svg);
}

.fi-gn {
  background-image: url(../flags/4x3/gn.svg);
}

.fi-gn.fis {
  background-image: url(../flags/1x1/gn.svg);
}

.fi-gp {
  background-image: url(../flags/4x3/gp.svg);
}

.fi-gp.fis {
  background-image: url(../flags/1x1/gp.svg);
}

.fi-gq {
  background-image: url(../flags/4x3/gq.svg);
}

.fi-gq.fis {
  background-image: url(../flags/1x1/gq.svg);
}

.fi-gr {
  background-image: url(../flags/4x3/gr.svg);
}

.fi-gr.fis {
  background-image: url(../flags/1x1/gr.svg);
}

.fi-gs {
  background-image: url(../flags/4x3/gs.svg);
}

.fi-gs.fis {
  background-image: url(../flags/1x1/gs.svg);
}

.fi-gt {
  background-image: url(../flags/4x3/gt.svg);
}

.fi-gt.fis {
  background-image: url(../flags/1x1/gt.svg);
}

.fi-gu {
  background-image: url(../flags/4x3/gu.svg);
}

.fi-gu.fis {
  background-image: url(../flags/1x1/gu.svg);
}

.fi-gw {
  background-image: url(../flags/4x3/gw.svg);
}

.fi-gw.fis {
  background-image: url(../flags/1x1/gw.svg);
}

.fi-gy {
  background-image: url(../flags/4x3/gy.svg);
}

.fi-gy.fis {
  background-image: url(../flags/1x1/gy.svg);
}

.fi-hk {
  background-image: url(../flags/4x3/hk.svg);
}

.fi-hk.fis {
  background-image: url(../flags/1x1/hk.svg);
}

.fi-hm {
  background-image: url(../flags/4x3/hm.svg);
}

.fi-hm.fis {
  background-image: url(../flags/1x1/hm.svg);
}

.fi-hn {
  background-image: url(../flags/4x3/hn.svg);
}

.fi-hn.fis {
  background-image: url(../flags/1x1/hn.svg);
}

.fi-hr {
  background-image: url(../flags/4x3/hr.svg);
}

.fi-hr.fis {
  background-image: url(../flags/1x1/hr.svg);
}

.fi-ht {
  background-image: url(../flags/4x3/ht.svg);
}

.fi-ht.fis {
  background-image: url(../flags/1x1/ht.svg);
}

.fi-hu {
  background-image: url(../flags/4x3/hu.svg);
}

.fi-hu.fis {
  background-image: url(../flags/1x1/hu.svg);
}

.fi-id {
  background-image: url(../flags/4x3/id.svg);
}

.fi-id.fis {
  background-image: url(../flags/1x1/id.svg);
}

.fi-ie {
  background-image: url(../flags/4x3/ie.svg);
}

.fi-ie.fis {
  background-image: url(../flags/1x1/ie.svg);
}

.fi-il {
  background-image: url(../flags/4x3/il.svg);
}

.fi-il.fis {
  background-image: url(../flags/1x1/il.svg);
}

.fi-im {
  background-image: url(../flags/4x3/im.svg);
}

.fi-im.fis {
  background-image: url(../flags/1x1/im.svg);
}

.fi-in {
  background-image: url(../flags/4x3/in.svg);
}

.fi-in.fis {
  background-image: url(../flags/1x1/in.svg);
}

.fi-io {
  background-image: url(../flags/4x3/io.svg);
}

.fi-io.fis {
  background-image: url(../flags/1x1/io.svg);
}

.fi-iq {
  background-image: url(../flags/4x3/iq.svg);
}

.fi-iq.fis {
  background-image: url(../flags/1x1/iq.svg);
}

.fi-ir {
  background-image: url(../flags/4x3/ir.svg);
}

.fi-ir.fis {
  background-image: url(../flags/1x1/ir.svg);
}

.fi-is {
  background-image: url(../flags/4x3/is.svg);
}

.fi-is.fis {
  background-image: url(../flags/1x1/is.svg);
}

.fi-it {
  background-image: url(../flags/4x3/it.svg);
}

.fi-it.fis {
  background-image: url(../flags/1x1/it.svg);
}

.fi-je {
  background-image: url(../flags/4x3/je.svg);
}

.fi-je.fis {
  background-image: url(../flags/1x1/je.svg);
}

.fi-jm {
  background-image: url(../flags/4x3/jm.svg);
}

.fi-jm.fis {
  background-image: url(../flags/1x1/jm.svg);
}

.fi-jo {
  background-image: url(../flags/4x3/jo.svg);
}

.fi-jo.fis {
  background-image: url(../flags/1x1/jo.svg);
}

.fi-jp {
  background-image: url(../flags/4x3/jp.svg);
}

.fi-jp.fis {
  background-image: url(../flags/1x1/jp.svg);
}

.fi-ke {
  background-image: url(../flags/4x3/ke.svg);
}

.fi-ke.fis {
  background-image: url(../flags/1x1/ke.svg);
}

.fi-kg {
  background-image: url(../flags/4x3/kg.svg);
}

.fi-kg.fis {
  background-image: url(../flags/1x1/kg.svg);
}

.fi-kh {
  background-image: url(../flags/4x3/kh.svg);
}

.fi-kh.fis {
  background-image: url(../flags/1x1/kh.svg);
}

.fi-ki {
  background-image: url(../flags/4x3/ki.svg);
}

.fi-ki.fis {
  background-image: url(../flags/1x1/ki.svg);
}

.fi-km {
  background-image: url(../flags/4x3/km.svg);
}

.fi-km.fis {
  background-image: url(../flags/1x1/km.svg);
}

.fi-kn {
  background-image: url(../flags/4x3/kn.svg);
}

.fi-kn.fis {
  background-image: url(../flags/1x1/kn.svg);
}

.fi-kp {
  background-image: url(../flags/4x3/kp.svg);
}

.fi-kp.fis {
  background-image: url(../flags/1x1/kp.svg);
}

.fi-kr {
  background-image: url(../flags/4x3/kr.svg);
}

.fi-kr.fis {
  background-image: url(../flags/1x1/kr.svg);
}

.fi-kw {
  background-image: url(../flags/4x3/kw.svg);
}

.fi-kw.fis {
  background-image: url(../flags/1x1/kw.svg);
}

.fi-ky {
  background-image: url(../flags/4x3/ky.svg);
}

.fi-ky.fis {
  background-image: url(../flags/1x1/ky.svg);
}

.fi-kz {
  background-image: url(../flags/4x3/kz.svg);
}

.fi-kz.fis {
  background-image: url(../flags/1x1/kz.svg);
}

.fi-la {
  background-image: url(../flags/4x3/la.svg);
}

.fi-la.fis {
  background-image: url(../flags/1x1/la.svg);
}

.fi-lb {
  background-image: url(../flags/4x3/lb.svg);
}

.fi-lb.fis {
  background-image: url(../flags/1x1/lb.svg);
}

.fi-lc {
  background-image: url(../flags/4x3/lc.svg);
}

.fi-lc.fis {
  background-image: url(../flags/1x1/lc.svg);
}

.fi-li {
  background-image: url(../flags/4x3/li.svg);
}

.fi-li.fis {
  background-image: url(../flags/1x1/li.svg);
}

.fi-lk {
  background-image: url(../flags/4x3/lk.svg);
}

.fi-lk.fis {
  background-image: url(../flags/1x1/lk.svg);
}

.fi-lr {
  background-image: url(../flags/4x3/lr.svg);
}

.fi-lr.fis {
  background-image: url(../flags/1x1/lr.svg);
}

.fi-ls {
  background-image: url(../flags/4x3/ls.svg);
}

.fi-ls.fis {
  background-image: url(../flags/1x1/ls.svg);
}

.fi-lt {
  background-image: url(../flags/4x3/lt.svg);
}

.fi-lt.fis {
  background-image: url(../flags/1x1/lt.svg);
}

.fi-lu {
  background-image: url(../flags/4x3/lu.svg);
}

.fi-lu.fis {
  background-image: url(../flags/1x1/lu.svg);
}

.fi-lv {
  background-image: url(../flags/4x3/lv.svg);
}

.fi-lv.fis {
  background-image: url(../flags/1x1/lv.svg);
}

.fi-ly {
  background-image: url(../flags/4x3/ly.svg);
}

.fi-ly.fis {
  background-image: url(../flags/1x1/ly.svg);
}

.fi-ma {
  background-image: url(../flags/4x3/ma.svg);
}

.fi-ma.fis {
  background-image: url(../flags/1x1/ma.svg);
}

.fi-mc {
  background-image: url(../flags/4x3/mc.svg);
}

.fi-mc.fis {
  background-image: url(../flags/1x1/mc.svg);
}

.fi-md {
  background-image: url(../flags/4x3/md.svg);
}

.fi-md.fis {
  background-image: url(../flags/1x1/md.svg);
}

.fi-me {
  background-image: url(../flags/4x3/me.svg);
}

.fi-me.fis {
  background-image: url(../flags/1x1/me.svg);
}

.fi-mf {
  background-image: url(../flags/4x3/mf.svg);
}

.fi-mf.fis {
  background-image: url(../flags/1x1/mf.svg);
}

.fi-mg {
  background-image: url(../flags/4x3/mg.svg);
}

.fi-mg.fis {
  background-image: url(../flags/1x1/mg.svg);
}

.fi-mh {
  background-image: url(../flags/4x3/mh.svg);
}

.fi-mh.fis {
  background-image: url(../flags/1x1/mh.svg);
}

.fi-mk {
  background-image: url(../flags/4x3/mk.svg);
}

.fi-mk.fis {
  background-image: url(../flags/1x1/mk.svg);
}

.fi-ml {
  background-image: url(../flags/4x3/ml.svg);
}

.fi-ml.fis {
  background-image: url(../flags/1x1/ml.svg);
}

.fi-mm {
  background-image: url(../flags/4x3/mm.svg);
}

.fi-mm.fis {
  background-image: url(../flags/1x1/mm.svg);
}

.fi-mn {
  background-image: url(../flags/4x3/mn.svg);
}

.fi-mn.fis {
  background-image: url(../flags/1x1/mn.svg);
}

.fi-mo {
  background-image: url(../flags/4x3/mo.svg);
}

.fi-mo.fis {
  background-image: url(../flags/1x1/mo.svg);
}

.fi-mp {
  background-image: url(../flags/4x3/mp.svg);
}

.fi-mp.fis {
  background-image: url(../flags/1x1/mp.svg);
}

.fi-mq {
  background-image: url(../flags/4x3/mq.svg);
}

.fi-mq.fis {
  background-image: url(../flags/1x1/mq.svg);
}

.fi-mr {
  background-image: url(../flags/4x3/mr.svg);
}

.fi-mr.fis {
  background-image: url(../flags/1x1/mr.svg);
}

.fi-ms {
  background-image: url(../flags/4x3/ms.svg);
}

.fi-ms.fis {
  background-image: url(../flags/1x1/ms.svg);
}

.fi-mt {
  background-image: url(../flags/4x3/mt.svg);
}

.fi-mt.fis {
  background-image: url(../flags/1x1/mt.svg);
}

.fi-mu {
  background-image: url(../flags/4x3/mu.svg);
}

.fi-mu.fis {
  background-image: url(../flags/1x1/mu.svg);
}

.fi-mv {
  background-image: url(../flags/4x3/mv.svg);
}

.fi-mv.fis {
  background-image: url(../flags/1x1/mv.svg);
}

.fi-mw {
  background-image: url(../flags/4x3/mw.svg);
}

.fi-mw.fis {
  background-image: url(../flags/1x1/mw.svg);
}

.fi-mx {
  background-image: url(../flags/4x3/mx.svg);
}

.fi-mx.fis {
  background-image: url(../flags/1x1/mx.svg);
}

.fi-my {
  background-image: url(../flags/4x3/my.svg);
}

.fi-my.fis {
  background-image: url(../flags/1x1/my.svg);
}

.fi-mz {
  background-image: url(../flags/4x3/mz.svg);
}

.fi-mz.fis {
  background-image: url(../flags/1x1/mz.svg);
}

.fi-na {
  background-image: url(../flags/4x3/na.svg);
}

.fi-na.fis {
  background-image: url(../flags/1x1/na.svg);
}

.fi-nc {
  background-image: url(../flags/4x3/nc.svg);
}

.fi-nc.fis {
  background-image: url(../flags/1x1/nc.svg);
}

.fi-ne {
  background-image: url(../flags/4x3/ne.svg);
}

.fi-ne.fis {
  background-image: url(../flags/1x1/ne.svg);
}

.fi-nf {
  background-image: url(../flags/4x3/nf.svg);
}

.fi-nf.fis {
  background-image: url(../flags/1x1/nf.svg);
}

.fi-ng {
  background-image: url(../flags/4x3/ng.svg);
}

.fi-ng.fis {
  background-image: url(../flags/1x1/ng.svg);
}

.fi-ni {
  background-image: url(../flags/4x3/ni.svg);
}

.fi-ni.fis {
  background-image: url(../flags/1x1/ni.svg);
}

.fi-nl {
  background-image: url(../flags/4x3/nl.svg);
}

.fi-nl.fis {
  background-image: url(../flags/1x1/nl.svg);
}

.fi-no {
  background-image: url(../flags/4x3/no.svg);
}

.fi-no.fis {
  background-image: url(../flags/1x1/no.svg);
}

.fi-np {
  background-image: url(../flags/4x3/np.svg);
}

.fi-np.fis {
  background-image: url(../flags/1x1/np.svg);
}

.fi-nr {
  background-image: url(../flags/4x3/nr.svg);
}

.fi-nr.fis {
  background-image: url(../flags/1x1/nr.svg);
}

.fi-nu {
  background-image: url(../flags/4x3/nu.svg);
}

.fi-nu.fis {
  background-image: url(../flags/1x1/nu.svg);
}

.fi-nz {
  background-image: url(../flags/4x3/nz.svg);
}

.fi-nz.fis {
  background-image: url(../flags/1x1/nz.svg);
}

.fi-om {
  background-image: url(../flags/4x3/om.svg);
}

.fi-om.fis {
  background-image: url(../flags/1x1/om.svg);
}

.fi-pa {
  background-image: url(../flags/4x3/pa.svg);
}

.fi-pa.fis {
  background-image: url(../flags/1x1/pa.svg);
}

.fi-pe {
  background-image: url(../flags/4x3/pe.svg);
}

.fi-pe.fis {
  background-image: url(../flags/1x1/pe.svg);
}

.fi-pf {
  background-image: url(../flags/4x3/pf.svg);
}

.fi-pf.fis {
  background-image: url(../flags/1x1/pf.svg);
}

.fi-pg {
  background-image: url(../flags/4x3/pg.svg);
}

.fi-pg.fis {
  background-image: url(../flags/1x1/pg.svg);
}

.fi-ph {
  background-image: url(../flags/4x3/ph.svg);
}

.fi-ph.fis {
  background-image: url(../flags/1x1/ph.svg);
}

.fi-pk {
  background-image: url(../flags/4x3/pk.svg);
}

.fi-pk.fis {
  background-image: url(../flags/1x1/pk.svg);
}

.fi-pl {
  background-image: url(../flags/4x3/pl.svg);
}

.fi-pl.fis {
  background-image: url(../flags/1x1/pl.svg);
}

.fi-pm {
  background-image: url(../flags/4x3/pm.svg);
}

.fi-pm.fis {
  background-image: url(../flags/1x1/pm.svg);
}

.fi-pn {
  background-image: url(../flags/4x3/pn.svg);
}

.fi-pn.fis {
  background-image: url(../flags/1x1/pn.svg);
}

.fi-pr {
  background-image: url(../flags/4x3/pr.svg);
}

.fi-pr.fis {
  background-image: url(../flags/1x1/pr.svg);
}

.fi-ps {
  background-image: url(../flags/4x3/ps.svg);
}

.fi-ps.fis {
  background-image: url(../flags/1x1/ps.svg);
}

.fi-pt {
  background-image: url(../flags/4x3/pt.svg);
}

.fi-pt.fis {
  background-image: url(../flags/1x1/pt.svg);
}

.fi-pw {
  background-image: url(../flags/4x3/pw.svg);
}

.fi-pw.fis {
  background-image: url(../flags/1x1/pw.svg);
}

.fi-py {
  background-image: url(../flags/4x3/py.svg);
}

.fi-py.fis {
  background-image: url(../flags/1x1/py.svg);
}

.fi-qa {
  background-image: url(../flags/4x3/qa.svg);
}

.fi-qa.fis {
  background-image: url(../flags/1x1/qa.svg);
}

.fi-re {
  background-image: url(../flags/4x3/re.svg);
}

.fi-re.fis {
  background-image: url(../flags/1x1/re.svg);
}

.fi-ro {
  background-image: url(../flags/4x3/ro.svg);
}

.fi-ro.fis {
  background-image: url(../flags/1x1/ro.svg);
}

.fi-rs {
  background-image: url(../flags/4x3/rs.svg);
}

.fi-rs.fis {
  background-image: url(../flags/1x1/rs.svg);
}

.fi-ru {
  background-image: url(../flags/4x3/ru.svg);
}

.fi-ru.fis {
  background-image: url(../flags/1x1/ru.svg);
}

.fi-rw {
  background-image: url(../flags/4x3/rw.svg);
}

.fi-rw.fis {
  background-image: url(../flags/1x1/rw.svg);
}

.fi-sa {
  background-image: url(../flags/4x3/sa.svg);
}

.fi-sa.fis {
  background-image: url(../flags/1x1/sa.svg);
}

.fi-sb {
  background-image: url(../flags/4x3/sb.svg);
}

.fi-sb.fis {
  background-image: url(../flags/1x1/sb.svg);
}

.fi-sc {
  background-image: url(../flags/4x3/sc.svg);
}

.fi-sc.fis {
  background-image: url(../flags/1x1/sc.svg);
}

.fi-sd {
  background-image: url(../flags/4x3/sd.svg);
}

.fi-sd.fis {
  background-image: url(../flags/1x1/sd.svg);
}

.fi-se {
  background-image: url(../flags/4x3/se.svg);
}

.fi-se.fis {
  background-image: url(../flags/1x1/se.svg);
}

.fi-sg {
  background-image: url(../flags/4x3/sg.svg);
}

.fi-sg.fis {
  background-image: url(../flags/1x1/sg.svg);
}

.fi-sh {
  background-image: url(../flags/4x3/sh.svg);
}

.fi-sh.fis {
  background-image: url(../flags/1x1/sh.svg);
}

.fi-si {
  background-image: url(../flags/4x3/si.svg);
}

.fi-si.fis {
  background-image: url(../flags/1x1/si.svg);
}

.fi-sj {
  background-image: url(../flags/4x3/sj.svg);
}

.fi-sj.fis {
  background-image: url(../flags/1x1/sj.svg);
}

.fi-sk {
  background-image: url(../flags/4x3/sk.svg);
}

.fi-sk.fis {
  background-image: url(../flags/1x1/sk.svg);
}

.fi-sl {
  background-image: url(../flags/4x3/sl.svg);
}

.fi-sl.fis {
  background-image: url(../flags/1x1/sl.svg);
}

.fi-sm {
  background-image: url(../flags/4x3/sm.svg);
}

.fi-sm.fis {
  background-image: url(../flags/1x1/sm.svg);
}

.fi-sn {
  background-image: url(../flags/4x3/sn.svg);
}

.fi-sn.fis {
  background-image: url(../flags/1x1/sn.svg);
}

.fi-so {
  background-image: url(../flags/4x3/so.svg);
}

.fi-so.fis {
  background-image: url(../flags/1x1/so.svg);
}

.fi-sr {
  background-image: url(../flags/4x3/sr.svg);
}

.fi-sr.fis {
  background-image: url(../flags/1x1/sr.svg);
}

.fi-ss {
  background-image: url(../flags/4x3/ss.svg);
}

.fi-ss.fis {
  background-image: url(../flags/1x1/ss.svg);
}

.fi-st {
  background-image: url(../flags/4x3/st.svg);
}

.fi-st.fis {
  background-image: url(../flags/1x1/st.svg);
}

.fi-sv {
  background-image: url(../flags/4x3/sv.svg);
}

.fi-sv.fis {
  background-image: url(../flags/1x1/sv.svg);
}

.fi-sx {
  background-image: url(../flags/4x3/sx.svg);
}

.fi-sx.fis {
  background-image: url(../flags/1x1/sx.svg);
}

.fi-sy {
  background-image: url(../flags/4x3/sy.svg);
}

.fi-sy.fis {
  background-image: url(../flags/1x1/sy.svg);
}

.fi-sz {
  background-image: url(../flags/4x3/sz.svg);
}

.fi-sz.fis {
  background-image: url(../flags/1x1/sz.svg);
}

.fi-tc {
  background-image: url(../flags/4x3/tc.svg);
}

.fi-tc.fis {
  background-image: url(../flags/1x1/tc.svg);
}

.fi-td {
  background-image: url(../flags/4x3/td.svg);
}

.fi-td.fis {
  background-image: url(../flags/1x1/td.svg);
}

.fi-tf {
  background-image: url(../flags/4x3/tf.svg);
}

.fi-tf.fis {
  background-image: url(../flags/1x1/tf.svg);
}

.fi-tg {
  background-image: url(../flags/4x3/tg.svg);
}

.fi-tg.fis {
  background-image: url(../flags/1x1/tg.svg);
}

.fi-th {
  background-image: url(../flags/4x3/th.svg);
}

.fi-th.fis {
  background-image: url(../flags/1x1/th.svg);
}

.fi-tj {
  background-image: url(../flags/4x3/tj.svg);
}

.fi-tj.fis {
  background-image: url(../flags/1x1/tj.svg);
}

.fi-tk {
  background-image: url(../flags/4x3/tk.svg);
}

.fi-tk.fis {
  background-image: url(../flags/1x1/tk.svg);
}

.fi-tl {
  background-image: url(../flags/4x3/tl.svg);
}

.fi-tl.fis {
  background-image: url(../flags/1x1/tl.svg);
}

.fi-tm {
  background-image: url(../flags/4x3/tm.svg);
}

.fi-tm.fis {
  background-image: url(../flags/1x1/tm.svg);
}

.fi-tn {
  background-image: url(../flags/4x3/tn.svg);
}

.fi-tn.fis {
  background-image: url(../flags/1x1/tn.svg);
}

.fi-to {
  background-image: url(../flags/4x3/to.svg);
}

.fi-to.fis {
  background-image: url(../flags/1x1/to.svg);
}

.fi-tr {
  background-image: url(../flags/4x3/tr.svg);
}

.fi-tr.fis {
  background-image: url(../flags/1x1/tr.svg);
}

.fi-tt {
  background-image: url(../flags/4x3/tt.svg);
}

.fi-tt.fis {
  background-image: url(../flags/1x1/tt.svg);
}

.fi-tv {
  background-image: url(../flags/4x3/tv.svg);
}

.fi-tv.fis {
  background-image: url(../flags/1x1/tv.svg);
}

.fi-tw {
  background-image: url(../flags/4x3/tw.svg);
}

.fi-tw.fis {
  background-image: url(../flags/1x1/tw.svg);
}

.fi-tz {
  background-image: url(../flags/4x3/tz.svg);
}

.fi-tz.fis {
  background-image: url(../flags/1x1/tz.svg);
}

.fi-ua {
  background-image: url(../flags/4x3/ua.svg);
}

.fi-ua.fis {
  background-image: url(../flags/1x1/ua.svg);
}

.fi-ug {
  background-image: url(../flags/4x3/ug.svg);
}

.fi-ug.fis {
  background-image: url(../flags/1x1/ug.svg);
}

.fi-um {
  background-image: url(../flags/4x3/um.svg);
}

.fi-um.fis {
  background-image: url(../flags/1x1/um.svg);
}

.fi-us {
  background-image: url(../flags/4x3/us.svg);
}

.fi-us.fis {
  background-image: url(../flags/1x1/us.svg);
}

.fi-uy {
  background-image: url(../flags/4x3/uy.svg);
}

.fi-uy.fis {
  background-image: url(../flags/1x1/uy.svg);
}

.fi-uz {
  background-image: url(../flags/4x3/uz.svg);
}

.fi-uz.fis {
  background-image: url(../flags/1x1/uz.svg);
}

.fi-va {
  background-image: url(../flags/4x3/va.svg);
}

.fi-va.fis {
  background-image: url(../flags/1x1/va.svg);
}

.fi-vc {
  background-image: url(../flags/4x3/vc.svg);
}

.fi-vc.fis {
  background-image: url(../flags/1x1/vc.svg);
}

.fi-ve {
  background-image: url(../flags/4x3/ve.svg);
}

.fi-ve.fis {
  background-image: url(../flags/1x1/ve.svg);
}

.fi-vg {
  background-image: url(../flags/4x3/vg.svg);
}

.fi-vg.fis {
  background-image: url(../flags/1x1/vg.svg);
}

.fi-vi {
  background-image: url(../flags/4x3/vi.svg);
}

.fi-vi.fis {
  background-image: url(../flags/1x1/vi.svg);
}

.fi-vn {
  background-image: url(../flags/4x3/vn.svg);
}

.fi-vn.fis {
  background-image: url(../flags/1x1/vn.svg);
}

.fi-vu {
  background-image: url(../flags/4x3/vu.svg);
}

.fi-vu.fis {
  background-image: url(../flags/1x1/vu.svg);
}

.fi-wf {
  background-image: url(../flags/4x3/wf.svg);
}

.fi-wf.fis {
  background-image: url(../flags/1x1/wf.svg);
}

.fi-ws {
  background-image: url(../flags/4x3/ws.svg);
}

.fi-ws.fis {
  background-image: url(../flags/1x1/ws.svg);
}

.fi-ye {
  background-image: url(../flags/4x3/ye.svg);
}

.fi-ye.fis {
  background-image: url(../flags/1x1/ye.svg);
}

.fi-yt {
  background-image: url(../flags/4x3/yt.svg);
}

.fi-yt.fis {
  background-image: url(../flags/1x1/yt.svg);
}

.fi-za {
  background-image: url(../flags/4x3/za.svg);
}

.fi-za.fis {
  background-image: url(../flags/1x1/za.svg);
}

.fi-zm {
  background-image: url(../flags/4x3/zm.svg);
}

.fi-zm.fis {
  background-image: url(../flags/1x1/zm.svg);
}

.fi-zw {
  background-image: url(../flags/4x3/zw.svg);
}

.fi-zw.fis {
  background-image: url(../flags/1x1/zw.svg);
}

.fi-arab {
  background-image: url(../flags/4x3/arab.svg);
}

.fi-arab.fis {
  background-image: url(../flags/1x1/arab.svg);
}

.fi-cefta {
  background-image: url(../flags/4x3/cefta.svg);
}

.fi-cefta.fis {
  background-image: url(../flags/1x1/cefta.svg);
}

.fi-cp {
  background-image: url(../flags/4x3/cp.svg);
}

.fi-cp.fis {
  background-image: url(../flags/1x1/cp.svg);
}

.fi-dg {
  background-image: url(../flags/4x3/dg.svg);
}

.fi-dg.fis {
  background-image: url(../flags/1x1/dg.svg);
}

.fi-eac {
  background-image: url(../flags/4x3/eac.svg);
}

.fi-eac.fis {
  background-image: url(../flags/1x1/eac.svg);
}

.fi-es-ct {
  background-image: url(../flags/4x3/es-ct.svg);
}

.fi-es-ct.fis {
  background-image: url(../flags/1x1/es-ct.svg);
}

.fi-es-ga {
  background-image: url(../flags/4x3/es-ga.svg);
}

.fi-es-ga.fis {
  background-image: url(../flags/1x1/es-ga.svg);
}

.fi-es-pv {
  background-image: url(../flags/4x3/es-pv.svg);
}

.fi-es-pv.fis {
  background-image: url(../flags/1x1/es-pv.svg);
}

.fi-eu {
  background-image: url(../flags/4x3/eu.svg);
}

.fi-eu.fis {
  background-image: url(../flags/1x1/eu.svg);
}

.fi-gb-eng {
  background-image: url(../flags/4x3/gb-eng.svg);
}

.fi-gb-eng.fis {
  background-image: url(../flags/1x1/gb-eng.svg);
}

.fi-gb-nir {
  background-image: url(../flags/4x3/gb-nir.svg);
}

.fi-gb-nir.fis {
  background-image: url(../flags/1x1/gb-nir.svg);
}

.fi-gb-sct {
  background-image: url(../flags/4x3/gb-sct.svg);
}

.fi-gb-sct.fis {
  background-image: url(../flags/1x1/gb-sct.svg);
}

.fi-gb-wls {
  background-image: url(../flags/4x3/gb-wls.svg);
}

.fi-gb-wls.fis {
  background-image: url(../flags/1x1/gb-wls.svg);
}

.fi-ic {
  background-image: url(../flags/4x3/ic.svg);
}

.fi-ic.fis {
  background-image: url(../flags/1x1/ic.svg);
}

.fi-pc {
  background-image: url(../flags/4x3/pc.svg);
}

.fi-pc.fis {
  background-image: url(../flags/1x1/pc.svg);
}

.fi-sh-ac {
  background-image: url(../flags/4x3/sh-ac.svg);
}

.fi-sh-ac.fis {
  background-image: url(../flags/1x1/sh-ac.svg);
}

.fi-sh-hl {
  background-image: url(../flags/4x3/sh-hl.svg);
}

.fi-sh-hl.fis {
  background-image: url(../flags/1x1/sh-hl.svg);
}

.fi-sh-ta {
  background-image: url(../flags/4x3/sh-ta.svg);
}

.fi-sh-ta.fis {
  background-image: url(../flags/1x1/sh-ta.svg);
}

.fi-un {
  background-image: url(../flags/4x3/un.svg);
}

.fi-un.fis {
  background-image: url(../flags/1x1/un.svg);
}

.fi-xk {
  background-image: url(../flags/4x3/xk.svg);
}

.fi-xk.fis {
  background-image: url(../flags/1x1/xk.svg);
}

:root {
  --pwp-color-primary: var(--primary-color);
  --pwp-color-error: hsl(345, 100%, 38%);
  --pwp-color-warning: hsl(45, 100%, 50%);
  --pwp-color-success: hsl(150, 98%, 35%);
  --pwp-color-background: hsl(213, 39%, 95%);
  --pwp-color-white: hsl(0, 0%, 100%);
  --pwp-color-black: hsl(0, 0%, 0%);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: var(--primary-500);
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: var(--bluegray-500);
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--primary-500);
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--bluegray-500);
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: var(--pwp-color-white);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: var(--pwp-color-white);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: var(--primary-500);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: var(--bluegray-500);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.1;
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.1;
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--primary-500);
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--bluegray-500);
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--primary-500);
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--bluegray-500);
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--primary-500);
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--bluegray-500);
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--primary-500);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--bluegray-500);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: var(--primary-500);
  --mat-mdc-button-ripple-color: rgba(var(--primary-500), 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: var(--bluegray-500);
  --mat-mdc-button-ripple-color: rgba(var(--bluegray-500), 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: var(--primary-500);
  --mat-mdc-button-ripple-color: rgba(var(--primary-500), 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: var(--bluegray-500);
  --mat-mdc-button-ripple-color: rgba(var(--bluegray-500), 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--primary-500);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--bluegray-500);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-500);
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--bluegray-500);
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--primary-500);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--primary-500);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--bluegray-500);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--bluegray-500);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--bluegray-500);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--bluegray-500);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-progress-bar-background {
  fill: var(--primary-100);
}

.mat-progress-bar-buffer {
  background-color: var(--primary-100);
}

.mat-progress-bar-fill::after {
  background-color: var(--primary-500);
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: var(--bluegray-100);
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: var(--bluegray-100);
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--bluegray-500);
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--primary-500);
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: var(--bluegray-500);
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: var(--primary-500);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: var(--bluegray-500);
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-500);
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--bluegray-500);
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: var(--bluegray-500);
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--primary-500);
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: var(--bluegray-500);
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--primary-500);
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: var(--bluegray-500);
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--primary-500);
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--bluegray-500);
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--primary-500);
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--bluegray-500);
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primary-500);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--bluegray-500);
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--primary-500);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--bluegray-500);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--primary-500);
  color: var(--pwp-color-white);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: var(--pwp-color-white);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.1;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--bluegray-500);
  color: var(--pwp-color-white);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: var(--pwp-color-white);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.1;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--bluegray-500);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--bluegray-500);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--bluegray-500);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-500);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary-500);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--primary-500);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-500);
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--primary-500);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--bluegray-500);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--bluegray-500);
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: var(--primary-500);
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: var(--pwp-color-white);
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: var(--primary-500);
  opacity: 0.2;
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--bluegray-500);
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: var(--pwp-color-white);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: var(--bluegray-500);
  opacity: 0.2;
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: var(--primary-100);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-500);
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--pwp-color-white);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: var(--bluegray-100);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--bluegray-500);
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--pwp-color-white);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: var(--primary-100);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: var(--primary-500);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--pwp-color-white);
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: var(--bluegray-100);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: var(--bluegray-500);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--pwp-color-white);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--pwp-color-white);
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--pwp-color-white);
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: var(--bluegray-500);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: var(--pwp-color-white);
  background: var(--primary-500);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: var(--bluegray-500);
  color: var(--pwp-color-white);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: var(--primary-500);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--primary-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--primary-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: var(--primary-500);
  color: var(--pwp-color-white);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--pwp-color-white);
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--primary-500);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--primary-500);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--bluegray-500);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--bluegray-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--bluegray-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--bluegray-500);
  color: var(--pwp-color-white);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--pwp-color-white);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--bluegray-500);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--bluegray-500);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: var(--primary-500);
}
.mat-datepicker-toggle-active.mat-accent {
  color: var(--bluegray-500);
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: var(--primary-500);
}
.mat-icon.mat-accent {
  color: var(--bluegray-500);
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: var(--pwp-color-white);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--primary-500);
  color: var(--pwp-color-white);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: var(--pwp-color-white);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--bluegray-500);
  color: var(--pwp-color-white);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: var(--primary-500);
  color: var(--pwp-color-white);
}
.mat-toolbar.mat-accent {
  background: var(--bluegray-500);
  color: var(--pwp-color-white);
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

survey-creator {
  --primary: var(--primary-500);
  --primary-light: var(--primary-100);
  --foreground: var(--pwp-color-black);
  --primary-foreground: var(--pwp-color-white);
  --secondary: var(--gray-500);
}

.p-autocomplete--full-width .p-autocomplete {
  width: 100%;
}
.p-autocomplete--full-width .p-inputtext {
  flex: 1;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  inset: 0 0 auto 0;
  min-height: 100%;
}

.p-card--grow-content .p-card {
  height: 100%;
}
.p-card--grow-content .p-card-body {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
}

.p-card--no-elevation .p-card {
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 90%);
}

.p-card--no-border .p-card {
  box-shadow: none;
  border: 0;
}

::part(vue-advanced-chat__quick-exit-button-icon) {
  font-family: "primeicons";
}

.p-checkbox--centered .p-checkbox-label {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

/* Make all ngneat/dialogs scroll if they have more content than visible by default */
.ngneat-dialog-content {
  overflow: auto !important;
}

.p-dialog.p-dialog-no-containing-block {
  transform: none;
}

.p-dropdown--empty-placeholder .p-placeholder {
  white-space: pre;
}

.p-dropdown--full-width {
  width: 100%;
}

.ql-toolbar .ql-formats .ql-image {
  display: none;
}

.ngx-json-viewer--ellipsis .segment-main.expandable {
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes ldio-ring {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-ring {
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  transform-origin: 0 0;
  transform: translateZ(0) scale(1);
  width: 100%;
}
.ldio-ring div {
  animation: ldio-ring 1s linear infinite;
  border-radius: 50%;
  border-top-color: transparent;
  border: 10px solid #3f51b5;
  box-sizing: content-box;
  height: 70px;
  left: 100px;
  position: absolute;
  top: 100px;
  width: 70px;
}

.loadingio-spinner-rolling-1vrmof83pbn {
  background: #ffffff;
  display: inline-block;
  height: 200px;
  overflow: hidden;
  width: 200px;
}

.p-messages--no-margin .p-message {
  margin: 0;
}

.p-password--full-width {
  width: 100%;
}
.p-password--full-width .p-password-input {
  width: 100%;
}

.p-radiobutton-label {
  margin-bottom: 0;
}

/* NOTE: organization settings are within a PrimeNG table cell that sets the white space settings as default, which often breaks responsiveness */
.p-element .p-datatable-resizable .p-datatable-tbody > tr > td {
  white-space: unset;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-radius: 4px 4px 0 0;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-radius: 0 0 4px 4px;
}

.p-tab-view--full-height .p-tabview {
  height: 100%;
  display: grid;
  grid-template: "tabs" auto "panels" 1fr/100%;
}
.p-tab-view--full-height .p-tabview-panel {
  height: 100%;
}

.p-tooltip.p-tooltip--large {
  max-width: 20rem;
}